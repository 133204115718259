import React, { useState, useEffect } from 'react'
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
  InputGroup,
} from 'react-bootstrap'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import signup from './images/signup1.avif'
import apiClient from './utils/apiClient'

const SignUp = ({ navigateTo }) => {
  const [formData, setFormData] = useState({
    password: '',
    email: '',
    otp: '',
    organization: '',
  })
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [serverOtp, setServerOtp] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(false) // Loading state
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const navigate = useNavigate()

  // Fetch organizations data on component mount
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const data = await apiClient('trainer/organizations/', 'GET', {
          'Content-Type': 'application/json',
        })
        if (Array.isArray(data)) {
          const formattedData = data.map((org) => ({
            value: org.id,
            label: org.name,
          }))
          setOrganizations(formattedData)
        } else {
          setError(
            'Server maintenance is scheduled until 6 AM. Please try again after that.'
          )
        }
      } catch (error) {
        setError(
          'Server maintenance is scheduled until 6 AM. Please try again after that.'
        )
      }
    };

    fetchOrganizations()
  }, [])

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
    setError('')
    setSuccess('')
  }

  // Handle organization selection change
  const handleOrganizationChange = (selectedOption) => {
    setFormData({
      ...formData,
      organization: selectedOption ? selectedOption.value : '',
    })
  }

  // Send OTP to the user's email
  const sendOtp = async () => {
    setCountdown(30);
    setIsDisabled(true);
    if (!formData.email) {
      setError("Please enter your email to receive OTP");
      setIsDisabled(false);
      return;
    }

    try {
      const response = await apiClient(
        "quiz/send-otp/",
        "POST",
        JSON.stringify({
          email: formData.email,
        }),
        { "Content-Type": "application/json" }
      );
      setOtpSent(true);
      setServerOtp(response.otp);
      setSuccess("OTP sent to your email!");

      // Start countdown
      
      setIsDisabled(true);

      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      setError("Can't send OTP, Check your email ID or Try again");
    }
  };



  // Submit the form data after OTP verification
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true) // Start loading animation

    try {
      const response = await apiClient(
        'quiz/create-user/',
        'POST',
        JSON.stringify({
          password: formData.password,
          email: formData.email,
          organization: formData.organization,
          otp: formData.otp,
        }),
        { 'Content-Type': 'application/json' }
      )

      if (response) {
        setSuccess('Signup Successful!');
        setTimeout(() => {
          navigate('/LoginPage')
        }, 2000)
      } else {
        setError("Can't Signup, please provide valid details")
      }
    } catch (error) {
      setError("Can't Signup, please provide valid details")
    } finally {
      setLoading(false) // Stop loading animation
    }
  };

  // Inline styles
  const styles = {
    container: {
      marginTop: "2rem",
      padding: "3rem",
      background: "rgba(255, 255, 255, 0.8)",
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)",
      borderRadius: "20px",
    },
    heading: {
      textAlign: "center",
      marginBottom: "2rem",
      fontWeight: "bold",
      fontSize: "2.5rem",
    },
    span: {
      color: "#ffa003",
    },
    label: {
      color: "#ffa003",
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    formGroup: {
      marginBottom: "1rem",
    },
    formControl: {
      height: "45px",
      borderRadius: "8px",
    },
    button: {
      width: "100%",
      padding: "10px",
      backgroundColor: "#ffa003",
      border: "none",
      color: "#ffffff",
      fontWeight: "bold",
      borderRadius: "8px",
      fontSize: "1rem",
    },
    otpButton: {
      marginLeft: "20px",
      backgroundColor: "#ffa003",
    },
    alert: {
      textAlign: "center",
      marginBottom: "1rem",
    },
    leftImage: {
      width: "100%",
      borderRadius: "15px",
    },
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <Container>
      <Row className="align-items-center">
        <Col md={6} className="d-flex justify-content-center">
          <img
            src={signup}
            alt="Sign Up"
            style={styles.leftImage}
          />
        </Col>
        <Col md={6}>
          <div style={styles.container}>
            <h1 style={styles.heading}>
              <span style={styles.span}>Sign</span> Up
            </h1>
            {error && <Alert style={styles.alert} variant="danger">{error}</Alert>}
            {success && <Alert style={styles.alert} variant="success">{success}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Email ID</Form.Label>
                <Form.Control
                  style={styles.formControl}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    required
                  />
                  <InputGroup.Text
                    onClick={togglePasswordVisibility}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#ffa003',
                      color: '#fff',
                    }}
                  >
                    {passwordVisible ? '👁️' : '👁️‍🗨️'}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>OTP Verification</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange} // Fixed error here
                    required
                  />
                  <Button
                    disabled={isDisabled}
                    style={{ ...styles.button, ...styles.otpButton }}
                    onClick={sendOtp}
                  >
                    {isDisabled ? countdown===30 ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{' '}
                      Sending OTP...
                    </>
                  ) : `Resend OTP in ${countdown}s` : "Send OTP"}
                  </Button>
                </div>
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Organization</Form.Label>
                <Select
                  options={organizations}
                  onChange={handleOrganizationChange}
                  placeholder="Search your organization"
                  isClearable
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '8px',
                      border: '1px solid #ced4da',
                      height: '45px',
                    }),
                  }}
                />
              </Form.Group>

              <div className="text-center">
                <Button style={styles.button} type="submit" disabled={loading}>
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{' '}
                      sigining up...
                    </>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </div>

              <div className="text-center mt-3">
                <span>Already have an account? </span>
                <Button
                  variant="link"
                  onClick={() => navigate('/LoginPage')}
                  style={{
                    color: '#ffa003',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Log In
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
